import * as React from 'react';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';
import Footer from '../components/footer';

const AboutPage = ({ data }) => {
	const page = data.about;
	return (
		<Layout>
			<HelmetDatoCms
				seo={page.seoMetaTags}
				bodyAttributes={{
					class: 'light-bg secondary-page',
				}}
			/>
			<main key='main' class={`background-${page.background}`}>
				{page.content.map((block) => (
					<section>
						{block.__typename === 'DatoCmsTextContent' && (
							<>
								{block.subtitle && <h6>{block.subtitle}</h6>}
								{block.size === 'large' && <h1>{block.text}</h1>}
								{block.size === 'medium' && <h2>{block.text}</h2>}
								{block.size === 'small' && <h3>{block.text}</h3>}
								{block.size === 'body' && <p class='lede'>{block.text}</p>}
							</>
						)}
						{block.__typename === 'DatoCmsLogoGrid' && (
							<div className='logo-grid'>
								{block.clients.map((client) => (
									<>
										{client.clientPage === null && (
											<div
												className='logo-item'
												dangerouslySetInnerHTML={{ __html: client.logo }}
											></div>
										)}
										{client.clientPage && (
											<Link
												to={`/client/${client.clientPage.slug}`}
												className='logo-item logo-item-link'
											>
												<div
													dangerouslySetInnerHTML={{ __html: client.logo }}
												></div>
												<div className='arrow'>
													<svg
														width='13'
														height='11'
														viewBox='0 0 13 11'
														fill='none'
														xmlns='http://www.w3.org/2000/svg'
													>
														<rect
															x='12'
															y='5'
															width='1'
															height='12'
															rx='0.5'
															transform='rotate(90 12 5)'
															fill='#DCFA6E'
														/>
														<path
															className='arrow-head'
															d='M8 1L12 5.5L8 10'
															stroke='#DCFA6E'
															stroke-linecap='round'
															stroke-linejoin='round'
														/>
													</svg>
												</div>
											</Link>
										)}
									</>
								))}
							</div>
						)}
						{block.__typename === 'DatoCmsLink' && (
							<Link to={block.url} class='btn'>
								<span className='btn-text'>{block.title}</span>
							</Link>
						)}
					</section>
				))}
			</main>
			<Footer color='blue'></Footer>
		</Layout>
	);
};

export default AboutPage;

export const query = graphql`
	query {
		about: datoCmsAbout {
			background
			content {
				... on DatoCmsLink {
					__typename
					id
					url
					title
					linkType
				}
				... on DatoCmsTextContent {
					__typename
					id
					text
					subtitle
					size
				}
				... on DatoCmsLogoGrid {
					__typename
					clients {
						logo
						clientPage {
							slug
						}
					}
				}
			}
			seoMetaTags {
				...GatsbyDatoCmsSeoMetaTags
			}
		}
	}
`;
